import { logoutFunc } from "api/auth/auth";
import { addToQueue } from "api/generic";
import axios from "axios";
import { API_OPERATIONS, CONFIG } from "constants/ApiConstant";
import store from "store";
import {
  setCameraList,
  setCameraListLoading,
  setData,
  setLoading,
  setEventLoading,
} from "store/slices/eventsSlice";

let listEvents = null;
export const listEvent = (values) => async (dispatch) => {
  dispatch(setLoading(true));
  const url = "project-management/list-events";
  if (listEventsPoling !== null) {
    listEventsPoling.cancel("New Call made");
    listEventsPoling = null;
  }
  if (listEvents !== null) {
    listEvents.cancel("New Call made");
    listEvents = null;
  }
  listEvents = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: listEvents.token,
  })
    .then((response) => {
      listEvents = null;
      if (response.data.statuscode === 200) {
        dispatch(setData(response.data.data));
        // setTimeout(() => {
        //   dispatch(getEventUpdatesApi(values));
        // }, 5000);
        dispatch(setLoading(false));
      } else if (response.data.statuscode === 403) {
        dispatch(setLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setLoading(false));
    });
};

let getSingleEvent = null;
export const getSingleEventData = async (values) => {
  const url = "project-management/get-event-details";
  if (getSingleEvent !== null) {
    getSingleEvent.cancel("New Call made");
    getSingleEvent = null;
  }
  getSingleEvent = axios.CancelToken.source();
  try {
    const response = await axios.post(API_OPERATIONS + url, values, {
      headers: CONFIG,
      cancelToken: getSingleEvent.token,
    });
    if (response.data.statuscode === 200) {
      getSingleEvent = null;

      return response.data.data;
    } else {
      getSingleEvent = null;
      return null;
    }
  } catch (error) {
    return null;
  }
};

let getUrlStream = null;
export const getCameraUrlStream = async (values) => {
  console.log(values);
  const url = "project-management/generate-camera-stream";
  if (getUrlStream !== null) {
    getUrlStream.cancel("New Call made");
    getUrlStream = null;
  }
  getUrlStream = axios.CancelToken.source();
  try {
    const response = await axios.post(API_OPERATIONS + url, values, {
      headers: CONFIG,
      cancelToken: getUrlStream.token,
    });
    if (response.data.statuscode === 200) {
      getUrlStream = null;
      return response.data.data;
    } else {
      getUrlStream = null;
      return null;
    }
  } catch (error) {
    return null;
  }
};

let stopUrlStream = null;
export const stopCameraUrlStream = async (values) => {
  const url = "project-management/stop-camera-streaming";
  if (stopUrlStream !== null) {
    stopUrlStream.cancel("New Call made");
    stopUrlStream = null;
  }
  stopUrlStream = axios.CancelToken.source();
  try {
    const response = await axios.post(API_OPERATIONS + url, values, {
      headers: CONFIG,
      cancelToken: stopUrlStream.token,
    });
    if (response.data.statuscode === 200) {
      stopUrlStream = null;
      return response.data.data;
    } else {
      stopUrlStream = null;
      return null;
    }
  } catch (error) {
    return null;
  }
};

let listCameraType = null;
export const listCameraByUser = (values) => async (dispatch) => {
  dispatch(setCameraListLoading(true));
  const url = "project-management/list-cameras-byuser";
  if (listCameraType !== null) {
    listCameraType.cancel("New Call made");
    listCameraType = null;
  }
  listCameraType = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: listCameraType.token,
  })
    .then((response) => {
      listCameraType = null;
      if (response.data.statuscode === 200) {
        dispatch(setCameraListLoading(false));
        dispatch(setCameraList(response.data.data));
      } else if (response.data.statuscode === 403) {
        dispatch(setCameraListLoading(false));
        dispatch(logoutFunc());
      } else {
        dispatch(setCameraListLoading(false));
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {
      dispatch(setCameraListLoading(false));
    });
};

let getUrlsByIdTypes = null;
export const getUrlsById = async (values) => {
  const url = "project-management/get-event-urls";
  if (getUrlsByIdTypes !== null) {
    getUrlsByIdTypes.cancel("New Call made");
    getUrlsByIdTypes = null;
  }
  getUrlsByIdTypes = axios.CancelToken.source();
  try {
    const response = await axios.post(API_OPERATIONS + url, values, {
      headers: CONFIG,
      cancelToken: getUrlsByIdTypes.token,
    });
    if (response.data.statuscode === 200) {
      getUrlsByIdTypes = null;
      return response.data.data;
    } else {
      getUrlsByIdTypes = null;
      return null;
    }
  } catch (error) {
    return null;
  }
};

let listEventsPoling = null;
let listEventsPolingTimeout = null;
export const listEventPolling = () => async (dispatch) => {
  clearTimeout(listEventsPolingTimeout);
  let values = JSON.parse(sessionStorage.getItem("eventValues"));
  const url = "project-management/list-events";
  if (listEventsPoling !== null) {
    listEventsPoling.cancel("New Call made");
    listEventsPoling = null;
  }
  listEventsPoling = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: listEventsPoling.token,
  })
    .then((response) => {
      listEventsPoling = null;
      if (response.data.statuscode === 200) {
        let oldData = store.getState().events.data;
        if (oldData.length !== response.data.data.length) {
          setData(response.data.data);
        }
        listEventsPolingTimeout = setTimeout(() => {
          if (window.location.pathname === "/app/camera-controls") {
            dispatch(listEventPolling());
            dispatch(setEventLoading(false));
          }
        }, 5000);
        dispatch(setData(response.data.data));
      } else if (response.data.statuscode === 403) {
        dispatch(logoutFunc());
      } else {
        listEventsPolingTimeout = setTimeout(() => {
          if (window.location.pathname === "/app/camera-controls") {
            dispatch(listEventPolling());
            dispatch(setEventLoading(false));
          }
        }, 10000);
      }
    })
    .catch((error) => {
      listEventsPolingTimeout = setTimeout(() => {
        if (window.location.pathname === "/app/camera-controls") {
          dispatch(listEventPolling());
          dispatch(setEventLoading(false));
        }
      }, 10000);
    });
};

let cameraStreamUpdates = null;
export const cameraStreamUpdatesData = async (values) => {
  const url = "project-management/camera-streaming-beat";
  if (cameraStreamUpdates !== null) {
    cameraStreamUpdates.cancel("New Call made");
    cameraStreamUpdates = null;
  }
  cameraStreamUpdates = axios.CancelToken.source();
  try {
    const response = await axios.post(API_OPERATIONS + url, values, {
      headers: CONFIG,
      cancelToken: cameraStreamUpdates.token,
    });
    if (response.data.statuscode === 200) {
      cameraStreamUpdates = null;
      return response.data.data;
    } else {
      getSingleEvent = null;
      return null;
    }
  } catch (error) {
    return null;
  }
};

let triggerAlerts = null;
export const triggerAlert = (values) => async (dispatch) => {
  const url = "project-management/send-event-alert";
  if (triggerAlerts !== null) {
    triggerAlerts.cancel("New Call made");
    triggerAlerts = null;
  }
  triggerAlerts = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: triggerAlerts.token,
  })
    .then((response) => {
      triggerAlerts = null;
      if (response.data.statuscode === 200) {
        addToQueue(url, false, response.data.message, 200);
      } else if (response.data.statuscode === 403) {
        dispatch(logoutFunc());
      } else {
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {});
};

let markEvents = null;
export const markEvent = (values) => async (dispatch) => {
  let url = "project-management/edit-event-status";
  if (markEvents !== null) {
    markEvents.cancel("New Call made");
    markEvents = null;
  }
  markEvents = axios.CancelToken.source();
  await axios({
    method: "POST",
    url: API_OPERATIONS + url,
    data: values,
    headers: CONFIG,
    cancelToken: markEvents.token,
  })
    .then((response) => {
      markEvents = null;
      if (response.data.statuscode === 200) {
        addToQueue(url, false, response.data.message, 200);
      } else if (response.data.statuscode === 403) {
        dispatch(logoutFunc());
      } else {
        addToQueue(
          url,
          true,
          response.data.message
            ? response.data.message
            : "Something went wrong! Please try again",
          response.data.statuscode ? response.data.statuscode : 500
        );
      }
    })
    .catch((error) => {});
};
